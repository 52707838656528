import React from "react";

export type TimelineEvent = {
  timeline: string;
  title: string;
  text: string;
};

const TimelineCard: React.FC<TimelineEvent> = ({ timeline, title, text }) => (
  <li className="model-segment">
    <div className="model-segment__card card">
      <h2 className="model-segment__title">{timeline}</h2>
      <p>
        <strong>{title}</strong>
        {text}
      </p>
    </div>
  </li>
);

export default TimelineCard;
