/// <reference lib="dom" />
import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Timeline from "../components/Timeline";
import VergilImage from "../images/portfolio-logo-vergil.svg";
import EveryDayLabsImage from "../images/portfolio-logo-everyday-labs.png";
import HandoffImage from "../images/portfolio-logo-handoff.svg";
import ProsperWorksImage from "../images/portfolio-logo-prosperworks.svg";
import WellMoneyImage from "../images/portfolio-logo-wellmoney.svg";
import NickelsImage from "../images/portfolio-logo-nickels.svg";
import YourBusinessImage from "../images/portfolio-logo-business-co.svg";
import Portfolio from "../components/Portfolio";
import EvaluateQuestions from "../components/EvaluateQuestions";
import Hero from "../components/Hero";
import Masthead from "../components/Masthead";

import timelineEvents from "../data/timelineEvents.json";

export const portfolioItems = [
  {
    displayText: "Creating a job share model that fuels productivity.",
    image: HandoffImage,
    alt: "Handoff",
    link: "https://teamhandoff.com/",
    newWindow: true,
  },
  {
    displayText: "Creating a place for people to get money when they need it.",
    image: WellMoneyImage,
    alt: "WellMoney",
    link: "https://well-money.com/",
    newWindow: true,
  },
  {
    displayText: "Empowering teams to secure the future of their business.",
    image: ProsperWorksImage,
    alt: "Prosper Works",
    link: "https://www.getprosperworks.com/",
    newWindow: true,
  },
  {
    displayText:
      "Working with school districts to reduce K-12 chronic absenteeism.",
    image: EveryDayLabsImage,
    alt: "EveryDay Labs",
    link: "https://everydaylabs.com/",
    newWindow: true,
  },
  {
    displayText:
      "Helping borrowers manage their student debt and avoid default.",
    image: NickelsImage,
    alt: "Nickels",
    link: "https://asknickels.com/",
    newWindow: true,
  },
  {
    displayText:
      "Working with state and local governments to reduce recidivism.",
    image: VergilImage,
    alt: "Vergil",
    link: "https://www.vergil.app/",
    newWindow: true,
  },
  {
    displayText: (
      <strong>
        We’re looking for entrepreneurs to build their companies with us.
      </strong>
    ),
    image: YourBusinessImage,
    alt: "Your Co.",
    link: "/studio",
    newWindow: false,
  },
];

// markup
const IndexPage: React.FC<DefaultPageProps> = ({ location, data }) => {
  const pageTitle = "ideas42 Venture Studio - Home";

  return (
    <>
      <Layout>
        <Helmet htmlAttributes={{ lang: "en" }}>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
          <meta
            property="og:url"
            content={data.site.siteMetadata.siteUrl + location.pathname}
          />
          <meta property="og:title" content={pageTitle} />
          <meta name="twitter:title" content={pageTitle} />
        </Helmet>
        <Masthead>
          <Hero
            title="We build companies that solve massive social challenges"
            titleClass="g-max-3"
            body="We recruit motivated individuals and surround them with design, technology, business building and behavioral science experts."
            bodyClass="hero__body--home g-max-3"
          />
          <div className="masthead__graphic--home" />
        </Masthead>
        <section className="standard-restricted-width standard-overflow-hidden g-max-4 g-section-vertical">
          <h2>Our model</h2>
          <p className="font-size-3">
            We recruit talented individuals from diverse backgrounds, with
            direct, lived experiences in our focus areas, and we work directly
            alongside them for 18 months in an intense research, behavioral
            design, prototype, sell, deployment, and fundraising process. At the
            end of 18 months, we expect each of our entrepreneurs to have a
            product in-market <strong>making a difference in the world</strong>,
            with capital to continue to grow far beyond the Venture Studio.
          </p>

          <Timeline timelineEvents={timelineEvents} />
        </section>

        <section className="standard-restricted-width">
          <EvaluateQuestions />
        </section>
        <section>
          <Portfolio portfolioItems={portfolioItems} />
        </section>
      </Layout>
    </>
  );
};

export default (props: DefaultPageProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query IndexPageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <IndexPage data={data} {...props} />}
  />
);
