import React from "react";
import TimelineCard, { TimelineEvent } from "./TimelineCard";
import "./Timeline.scss";

type TimelineProps = {
  timelineEvents: TimelineEvent[];
};

const Timeline: React.FC<TimelineProps> = ({
  timelineEvents,
}: TimelineProps) => (
  <ul className="model-timeline">
    {timelineEvents.map((event) => (
      <TimelineCard key={event.title} {...event} />
    ))}
  </ul>
);

export default Timeline;
