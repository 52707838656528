import * as React from "react";

import "./EvaluateQuestions.scss";

import EvaluateGraphic1 from "../images/evaluate-q-1.svg";
import EvaluateGraphic2 from "../images/evaluate-q-2.svg";
import EvaluateGraphic3 from "../images/evaluate-q-3.svg";

const EvaluateQuestions: React.FC = () => (
  <>
    <section>
      <h2>How we evaluate business opportunities</h2>
      <p className="font-size-3 g-max-4">
        We work with our entrepreneurs iteratively through a research, design,
        prototype, sell, deployment, and fundraising process, roughly chunked in
        three, six month long periods. We apply a behavioral science lens to
        collaboratively identify business opportunities (we don&apos;t expect
        our entrepreneurs to join the studio with a specific business idea), and
        focus on enterprise, value-based Software as a Service as our vehicle to
        scale our impact. When evaluating opportunities
        <b> we ask, is there&#8230;</b>
      </p>
      <ul className="font-size-3 evaluate__questions">
        <li className="evaluate_q">
          <img
            className="evaluate_q__image"
            src={EvaluateGraphic1}
            alt="An illustration of of a purple circle (the intention) and a pink triangle (the action). In between them is a harsh looking yellow jagged line (the gap)."
          />
          An existing <strong>intention → action gap</strong>?
          <small className="font-size-0">
            (e.g. where an individual plans to do or not do something, but fails
            to follow through on their intention)
          </small>
        </li>
        <li className="evaluate_q">
          <img
            className="evaluate_q__image"
            src={EvaluateGraphic2}
            alt="An illustration of a purple building and red building to represent an enterprise."
          />
          An <strong>enterprise</strong> who benefits from closing that
          intention → action gap?
        </li>
        <li className="evaluate_q">
          <img
            className="evaluate_q__image"
            src={EvaluateGraphic3}
            alt="An illustration of a purple circle (the intention) and a pgink triangle (the action). Connecting them is a yellow line resembling a bolt of electricity (the data powered intervention)."
          />
          A <strong>data powered intervention</strong> that helps the individual
          follow through on their intention?
        </li>
      </ul>
      <p className="font-size-2 g-max-2-half">
        Pursuing opportunities with these three characteristics ensures we are
        actually helping people achieve their desired goals, and allows us to
        make value based sales to existing enterprises, while building
        technology to deliver that value, powered by data, and informed by
        behavioral science. This makes our businesses scalable, impactful and
        defensible.
      </p>
    </section>
  </>
);

export default EvaluateQuestions;
