import React, { ReactNode } from "react";

export type PortfolioItem = {
  link: string;
  image: string;
  alt: string;
  displayText: ReactNode;
  newWindow: boolean;
};

const PortfolioItemDisplay: React.FC<PortfolioItem> = ({
  link,
  image,
  alt,
  displayText,
  newWindow,
}) => {
  const openProps = newWindow
    ? {
        target: "_blank",
        rel: "noopener",
      }
    : {};

  return (
    <li>
      <a className="portfolio__org card" href={link} {...openProps}>
        <img src={image} alt={alt} />
        {displayText}
      </a>
    </li>
  );
};

export default PortfolioItemDisplay;
