import * as React from "react";
import PortfolioList, { PortfolioListProps } from "./PortfolioList";
import "./Portfolio.scss";

export const portfolioData = {
  title: "Our portfolio",
  text: "Companies we're working with today.",
};

const Portfolio: React.FC<PortfolioListProps> = ({ portfolioItems }) => (
  <section className="standard-restricted-width g-section-vertical portfolio">
    <header className="g-max-2">
      <h2>{portfolioData.title}</h2>
      <p className="font-size-3">{portfolioData.text}</p>
    </header>
    <div className="portfolio__listBackground" />
    <PortfolioList portfolioItems={portfolioItems} />
  </section>
);

export default Portfolio;
