import * as React from "react";
import PortfolioItemDisplay, { PortfolioItem } from "./PortfolioItemDisplay";

export type PortfolioListProps = {
  portfolioItems: PortfolioItem[];
};

const PortfolioList: React.FC<PortfolioListProps> = ({ portfolioItems }) => (
  <ul className="portfolio__list">
    {portfolioItems.map((item) => (
      <PortfolioItemDisplay {...item} key={item.link} />
    ))}
  </ul>
);

export default PortfolioList;
